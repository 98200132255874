@font-face {
  font-family: 'Rubik';
  src: local('RubikRegular'),
  url('./fonts/Rubik-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'RubikMedium';
  src: local('RubikMedium'), url('./fonts/Rubik-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'RubikLight';
  src: local('RubikLight'), url('./fonts/Rubik-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'RubikBold';
  src: local('RubikBold'), url('./fonts/Rubik-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans';
  src: local('OpenSansRegular'),
  url('./fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansLight';
  src: local('OpenSansLight'),
  url('./fonts/OpenSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansSemiBold';
  src: local('OpenSansSemiBold'),
  url('./fonts/OpenSans-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansBold';
  src: local('OpenSansBold'),
  url('./fonts/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansExtraBold';
  src: local('OpenSansExtraBold'),
  url('./fonts/OpenSans-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSansBold';
  src: local('PlusJakartaSansBold'),
  url('./fonts/PlusJakartaSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSansExtraBold';
  src: local('PlusJakartaSansExtraBold'),
  url('./fonts/PlusJakartaSans-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSansExtraLight';
  src: local('PlusJakartaSansExtraLight'),
  url('./fonts/PlusJakartaSans-ExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSansLight';
  src: local('PlusJakartaSansLight'),
  url('./fonts/PlusJakartaSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSansMedium';
  src: local('PlusJakartaSansMedium'),
  url('./fonts/PlusJakartaSans-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSansSemiBold';
  src: local('PlusJakartaSansSemiBold'),
  url('./fonts/PlusJakartaSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSansRegular';
  src: local('PlusJakartaSansRegular'),
  url('./fonts/PlusJakartaSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'InterBold';
  src: local('InterBold'),
  url('./fonts/Inter/Inter-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'InterMedium';
  src: local('InterMedium'),
  url('./fonts/Inter/Inter-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'InterRegular';
  src: local('InterRegular'),
  url('./fonts/Inter/Inter-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'InterSemiBold';
  src: local('InterSemiBold'),
  url('./fonts/Inter/Inter-SemiBold.ttf') format('truetype');
}
.grecaptcha-badge {
  visibility: hidden;
}

html,
body,
#app,
#app > div,
#root {
  height: 100%;
  margin: 0;
}

html,
body,
#root {
  min-height: 100vh;
  margin: 0;
  scrollbar-gutter: 'stable'
}

a:-webkit-any-link {
  text-decoration: none;
}
